<campus-text-field
  *ngIf="type === 'textfield'"
  #trigger
  role="combobox"
  [label]="label"
  [value]="displayText"
  [leadingIcon]="leadingIcon"
  [supportingText]="supportingText"
  [supportingTextVariant]="supportingTextVariant"
  [trailingSupportingText]="trailingSupportingText"
  [required]="required"
  [error]="manualError"
  [leadingText]="leadingText"
  [trailingText]="trailingText"
  [disabled]="disabled"
  [ariaDescribedBy]="ariaDescribedBy"
  [ariaLabelledBy]="ariaLabelledBy"
  [ariaLabel]="ariaLabel"
  [placeholder]="placeholder"
  [active]="panelOpen"
  [tabindex]="disabled ? -1 : 0"
  [clearable]="clearable"
  (clear)="clearSelection($event)"
  [trailingIcon]="trailingIcon"
  readOnly
  class="[ cursor-pointer ]"
  (focusin)="onFocusIn()"
>
</campus-text-field>

<button
  #trigger
  *ngIf="type === 'button'"
  role="combobox"
  filled-button
  [trailingIcon]="trailingIcon"
  [disabled]="disabled"
  [attr.ariaDescribedBy]="ariaDescribedBy"
  [attr.ariaLabelledBy]="ariaLabelledBy"
  [ariaLabel]="ariaLabel"
  >{{ label }}</button
>
<div #trigger *ngIf="type === 'icon-button'" class="[ flex items-center cursor-pointer ]">
  <button
    role="combobox"
    inline-icon-button
    [icon]="icon"
    [disabled]="disabled"
    [attr.ariaDescribedBy]="ariaDescribedBy"
    [attr.ariaLabelledBy]="ariaLabelledBy"
    [ariaLabel]="ariaLabel"
  ></button>
  <button class="[ -m-is-3xs ]" segmented-dropdown-button></button>
</div>

<dcr-badge
  *ngIf="badge && multiple && value?.length"
  [count]="value.length"
  class="[ absolute transform -translate-y-1/2 translate-x-1/2 ]"
  [ngClass]="type === 'icon-button' ? ['top-2xs', 'right-m'] : ['top-0', 'right-0']"
></dcr-badge>
<dcr-badge
  *ngIf="badge && !multiple && value != null"
  [count]="1"
  class="[ absolute transform -translate-y-1/2 translate-x-1/2 ]"
  [ngClass]="type === 'icon-button' ? ['top-2xs', 'right-m'] : ['top-0', 'right-0']"
></dcr-badge>

<campus-menu
  *ngIf="trigger && !disabled"
  role="listbox"
  [anchor]="trigger"
  [anchorSelector]="type === 'textfield' ? '.input-group' : undefined"
  [multiple]="multiple"
  [sortComparator]="sortComparator"
  [selectableController]="selectableController"
  [navigableController]="navigableController"
  [typeaheadController]="typeaheadController"
  [positioning]="menuPositioning"
  [anchorCorner]="menuAlign === 'start' ? 'end-start' : 'end-end'"
  [allowDownToOpen]="true"
  [typeaheadActive]="typeaheadActive"
  [typeaheadDelay]="typeaheadDelay"
  [open]="panelOpen"
  (openChange)="onPanelOpenChange($event)"
  [ngClass]="{ 'w-full': clampMenuWidth }"
  [style.max-width]="clampMenuWidth ? selectWidth + 'px' : undefined"
  [style.min-width]="selectWidth + 'px'"
  [skipRestoreFocus]="skipRestoreFocus"
>
  <ng-content></ng-content>
</campus-menu>
