import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { defer, merge, Observable } from 'rxjs';
import { startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { asEmptyAttribute, InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';
import { FormElementCore } from '../core/form-element-core';
import { ActiveDescendantListController } from '../core/navigation/activedescendant-list.controller';
import { SelectableListController } from '../core/selection/selectable-list.controller';
import { DEFAULT_TYPEAHEAD_BUFFER_TIME, TypeaheadController } from '../core/typeahead/typeahead.controller';
import { MenuItemInterface, MenuItemSelectionChange } from '../menu/menu-item/menu-item.component';

import { ButtonCoreComponent } from '../button/button-core/button-core.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { OptionGroupComponent, OptionGroupSelectionChange } from './option-group/option-group.component';
import { SelectOptionComponent } from './select-option/select-option.component';

import '@diekeure/dcr-components/dcr-badge.js';

export class SelectSelectionChange<T = any> {
  constructor(public selected: T, public added: T, public removed: T) {}
}
/**
 * Select Component
 * Selects an item from a list of options
 *
 * Selects options can be unselected or selected, using `formControlName`, `ngModel` or `value` (not recommended).

 * @example
 * <campus-select [label]="Select Label">
 *  <campus-select-option
 *    *ngFor="let option of options"
 *    [value]="option.value"
 *    [label]="option.label"
 *    [disabled]="option.disabled"
 *  />
 * </campus-select>
 *
 * @example
 * <form [formGroup]="form">
 *  <campus-select [label]="Select Label" formControlName="fruit">
 *    <campus-select-option
 *      *ngFor="let option of options"
 *      [value]="option.value"
 *      [label]="option.label"
 *      [disabled]="option.disabled"
 *    />
 *  </campus-select>
 * </form>
 *
 * @documentation https://docs.kabas.be/components/select
 *
 * @see A11Y: Switch Pattern:  https://www.w3.org/WAI/ARIA/apg/patterns/combobox/
 *
 */
@Component({
  selector: 'campus-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: SelectComponent, multi: true },
  ],
})
export class SelectComponent
  extends FormElementCore<any>
  implements OnChanges, AfterContentInit, AfterViewInit, OnDestroy
{
  @ContentChildren(forwardRef(() => SelectOptionComponent), { descendants: true }) items: QueryList<MenuItemInterface>;

  @ContentChildren(forwardRef(() => OptionGroupComponent), { descendants: true })
  optionGroups: QueryList<OptionGroupComponent>;

  @ViewChild('trigger') trigger: TextFieldComponent | ButtonCoreComponent;

  @HostBinding('class')
  defaultClasses = ['relative', 'inline-block', 'outline-none', 'w-fit'];

  @Input() type: 'textfield' | 'button' | 'icon-button' = 'textfield';

  @Input() menuPositioning: 'absolute' | 'fixed' | 'popover' = 'popover';
  @Input() menuAlign: 'start' | 'end' = 'start';
  @Input() clampMenuWidth: InputBooleanAttribute;
  @Input() supportingTextVariant: 'default' | 'warn';

  @Input() quick: InputBooleanAttribute;
  @Input() closeOnSelect: InputBooleanAttribute;
  @Input() skipRestoreFocus: InputBooleanAttribute = false;
  @Input() multiple: InputBooleanAttribute;
  @Input() checkbox: InputBooleanAttribute;

  //#region Textfield
  @Input() leadingText: string;
  @Input() trailingText: string;
  @Input() leadingIcon: string;
  @Input() trailingIcon = 'cds-comp:arrow-drop-down';
  @Input() icon: string;
  @Input() supportingText: string;
  @Input() trailingSupportingText: string;
  @Input() placeholder: string;
  @Input() clearable: InputBooleanAttribute;

  @Input() ariaDescribedBy: string;

  @Input() typeaheadDelay = DEFAULT_TYPEAHEAD_BUFFER_TIME;
  @Input() typeaheadActive = true;

  @Input() sortComparator: (a: MenuItemInterface, b: MenuItemInterface, options: MenuItemInterface[]) => number;

  @Input() emptyValue = undefined;

  @Input() badge: InputBooleanAttribute;

  //#endregion

  //#region Private Properties

  private _ngZone = inject(NgZone);
  private _destroyed$ = new EventEmitter<void>();

  private readonly itemsSelectionChanges: Observable<MenuItemSelectionChange> = defer(() => {
    const items = this.items;

    if (items) {
      return items.changes.pipe(
        startWith(items),
        switchMap(() => merge(...items.map((option) => option.selectionChange)))
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.itemsSelectionChanges)
    );
  }) as Observable<MenuItemSelectionChange>;

  private readonly groupsSelectionChanges: Observable<OptionGroupSelectionChange> = defer(() => {
    const groups = this.optionGroups;

    if (groups) {
      return groups.changes.pipe(
        startWith(groups),
        switchMap(() => merge(...groups.map((group) => group.selectionChange)))
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.groupsSelectionChanges)
    );
  }) as Observable<OptionGroupSelectionChange>;

  //#endregion

  //#region Public Properties
  displayText: string;

  navigableController: ActiveDescendantListController<MenuItemInterface>;
  selectableController: SelectableListController<MenuItemInterface>;
  typeaheadController: TypeaheadController;

  manualError: ValidationErrors | null;
  panelOpen = false;
  anchor: HTMLElement;

  get selectWidth() {
    return +this.trigger?.elementRef?.nativeElement?.getBoundingClientRect().width;
  }

  //#endregion

  //#region Outputs
  @Output() selectionChange = new EventEmitter<SelectSelectionChange>();

  //#endregion

  //#region Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiple) {
      this.multiple = isTruthyInput(this.multiple);
      this.closeOnSelect = isTruthyInput(this.closeOnSelect);
    }
    if (changes.checkbox) {
      this.checkbox = isTruthyInput(this.checkbox);
    }
    if (changes.closeOnSelect) {
      this.closeOnSelect = isTruthyInput(this.closeOnSelect);
    }
    if (this.closeOnSelect === undefined) {
      this.closeOnSelect = true;
    }
    if (changes.clampMenuWidth) {
      this.clampMenuWidth = isTruthyInput(this.clampMenuWidth);
    }
    if (changes.clearable) {
      this.clearable = isTruthyInput(this.clearable);
    }
    if (changes.badge) {
      this.badge = isTruthyInput(this.badge);
    }
    if (changes.skipRestoreFocus) {
      this.skipRestoreFocus = isTruthyInput(this.skipRestoreFocus);
    }
  }

  ngAfterContentInit(): void {
    this._initNavigableController();
    this._initSelectableController();
    this._initTypeaheadController();

    this.formControlErrors$
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((errors) => {
        this.manualError = errors;
        this._cd.markForCheck();
      });

    this.items.changes.pipe(startWith([]), takeUntil(this.destroyed$)).subscribe(() => {
      this._resetOptions();
    });

    this.formControl?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onValueChanged();
    });
  }

  ngAfterViewInit(): void {
    this.anchor = this.trigger.elementRef?.nativeElement;
  }

  //#endregion

  //#region Public Methods
  override handleFocusChange() {
    // only listen to panelOpen changes
    // so ignore the formElementCore focus change
    return;
  }

  onFocusIn() {
    // focus in triggered by the textfield
    if (this.readOnly) return;

    this.focused = true;
    this.formControl?.markAsTouched();

    this.isFocused = asEmptyAttribute(this.focused);
  }

  onBlurred() {
    this.focused = false;
    this.onTouched();
  }

  onPanelOpenChange(event) {
    // only if panel is closed call the blur event
    // for now this is the only way to call the formControl touched
    // as we can't trust on the blur event of the textfield
    this.panelOpen = event;
    if (!this.panelOpen) {
      this.onBlurred();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!event.defaultPrevented && this.panelOpen) {
      this.typeaheadController.onKeydown(event);
    }
  }

  protected onValueChanged(shouldPropagate = true) {
    super.onValueChanged(shouldPropagate);
    if (!this.items || !this.selectableController) return;
    if (!shouldPropagate) return;
    const selectedValues = this.selectableController.selected.map((option) => option.value);

    const value = this.formControl?.value || this.value;

    if (this.multiple && Array.isArray(value) && value.length === 0) {
      this.selectableController.clear();
      this._updateDisplayText();
    } else if (value == null) {
      this.selectableController.clear();
      this._updateDisplayText();
    } else if (!this.compareWith(value, this.multiple ? selectedValues : selectedValues[0])) {
      const arrValue = Array.isArray(value) ? value : [value];
      const selectedItems = this.items.filter((item) => {
        return arrValue.some((v) => this.compareWith(item.value, v));
      });
      selectedItems.forEach((selectedItem) => selectedItem.select());
    }
  }

  clearSelection(event: MouseEvent) {
    this.value = this.emptyValue;
    this.selectableController.clear();
  }

  public override writeValue(value: any): void {
    super.writeValue(value);

    if (Array.isArray(value) && this.selectableController) {
      this.selectableController?.clear(false);

      const selectedItems = (this.items || []).filter((item) => {
        return value.some((v) => this.compareWith(item.value, v));
      });
      this.selectableController.silentSelect(...selectedItems);
      this.items.forEach((item) => item.deselect(false));
      selectedItems.forEach((selectedItem) => selectedItem.select(false));

      this._updateDisplayText();
    }
  }

  //#endregion

  //#region Private Methods
  private _initNavigableController(): void {
    const items = this._getNavigableItems();

    this.navigableController = new ActiveDescendantListController(items);
  }

  private _initSelectableController(): void {
    const initialSelection: MenuItemInterface[] = this._getSelectionFromValue();

    this.selectableController = new SelectableListController(
      isTruthyInput(this.multiple),
      initialSelection,
      true,
      this.compareWith
    );

    this._updateDisplayText();

    this.selectableController.changed.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (this.multiple) {
        this.items.forEach((item) => item.deselect(false));
        event.selected.forEach((option) => option.select(false));
      } else {
        event.removed.forEach((option) => option.deselect(false));
        event.added.forEach((option) => option.select(false));
      }

      const { selected } = event;

      const selection = {
        selected: this.multiple ? selected.map((s) => s.value) : selected[0]?.value,
        added: event.added.map((s) => s.value),
        removed: event.removed.map((s) => s.value),
      };

      if (!this.onChange) {
        this.selectionChange.emit(new SelectSelectionChange(selection.selected, selection.added, selection.removed));
      }
    });
  }

  private _initTypeaheadController(): void {
    this.typeaheadController = new TypeaheadController(() => {
      return {
        getItems: () => this._getNavigableItems(),
        typeaheadBufferTime: this.typeaheadDelay,
        active: this.typeaheadActive,
      };
    });
  }

  private _getNavigableItems(): MenuItemInterface[] {
    let items = [];
    if (this.items && this.items.length > 0) {
      items = this.items.toArray();
    } else if (this.optionGroups && this.optionGroups.length > 0) {
      items = this.optionGroups
        .toArray()
        .reduce((arr, group) => [...arr, ...(group.multiple ? [group] : []), ...group.items.toArray()], []);
    }
    return items;
  }

  private _sortValues() {
    if (this.multiple) {
      const items = this.items.toArray();

      this.selectableController.sort((a, b) => {
        return this.sortComparator ? this.sortComparator(a, b, items) : items.indexOf(a) - items.indexOf(b);
      });
    }
  }

  private _getSelectionFromValue() {
    if (this.value == null) return [];

    const items = this.items.toArray();

    const value = this.formControl?.value || this.value;
    const values = Array.isArray(value) ? value : [value];
    const selection = values.map((v) => items.find((item) => this.compareWith(item.value, v))).filter(Boolean);

    return selection;
  }

  private _resetOptions() {
    if (this.value != null) {
      this.selectableController.clear();
      this.selectableController.select(...this._getSelectionFromValue());
      this._updateDisplayText();
    }

    this.navigableController.setItems(this._getNavigableItems());

    const changedOrDestroyed = merge(this.items.changes, this.destroyed$);
    const groupChangedOrDestroyed = merge(this.optionGroups.changes, this.destroyed$);

    this.itemsSelectionChanges.pipe(takeUntil(changedOrDestroyed)).subscribe((event) => {
      if (event.isUserInput) {
        this.formControl?.markAsTouched();
      }
      this._onSelect(event.source, event.isUserInput);
      this.panelOpen = this.closeOnSelect ? false : this.panelOpen;
      this._cd.markForCheck();
    });

    this.groupsSelectionChanges.pipe(takeUntil(groupChangedOrDestroyed)).subscribe((event) => {
      if (event.isUserInput) {
        this.formControl?.markAsTouched();
      }
      this._onGroupSelected(event.source, event.isUserInput);
      this.panelOpen = this.closeOnSelect ? false : this.panelOpen;
      this._cd.markForCheck();
    });
  }

  private _onSelect(item: MenuItemInterface, isUserInput: boolean): void {
    const wasSelected = this.selectableController.isSelected(item);

    const updateValue = () => {
      const selection = this.selectableController.selected.map((option) => option.value);

      const value = this.multiple ? selection : selection.length ? selection[0] : null;
      if (this.onChange) {
        this.onChange(value);
      } else {
        this.value = value;
      }
      this._updateDisplayText();
    };

    if (item.value == null && !this.multiple) {
      this.selectableController.silentSelect(...[]);
      this.items.forEach((i) => i.deselect(false));

      updateValue();
    } else {
      if (wasSelected !== item.selected) {
        if (!this.multiple) {
          this.selectableController.clear();
        }
        if (item.selected) this.selectableController.select(item);
        else this.selectableController.deselect(item);
      }

      if (isUserInput) {
        this.navigableController.setActiveItem(item);
      }

      if (this.multiple) {
        this._sortValues();
      }
    }

    if (wasSelected !== this.selectableController.isSelected(item)) {
      updateValue();
    }
  }

  private _onGroupSelected(group: OptionGroupComponent, isUserInput: boolean): void {
    if (group.multiple) {
      group.items
        .filter((item) => !item.disabled)
        .forEach((item) => {
          if (group.selected) {
            item.select();
          } else {
            item.deselect();
          }
        });
    }
  }

  private _updateDisplayText() {
    this.displayText = this.selectableController?.selected.map((option) => option.label).join(', ');
    this._cd.markForCheck();
  }
  //#endregion
}
